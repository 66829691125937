import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchRoleList, addRole, updateRole, delRole, updateRolePer, fetchPermission } from "@/api/sys";
import Pagination from "@/components/Pagination";
export default {
  name: "adminList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        keyword: ""
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      btnLoading: false,
      // 角色
      textMap: {
        update: "编辑角色",
        create: "添加角色"
      },
      dialogTitle: "",
      roleDialog: false,
      roleForm: {
        id: "",
        name: "",
        description: ""
      },
      // 权限
      authDialog: false,
      transferData: [],
      authForm: {
        role_id: "",
        permission: []
      },
      testArr: [{
        name: "menu1",
        content: [{
          id: 1,
          name: "az"
        }, {
          id: 2,
          name: "ah"
        }, {
          id: 3,
          name: "ab"
        }]
      }, {
        name: "menu2",
        content: [{
          id: 4,
          name: "ac"
        }, {
          id: 5,
          name: "ad"
        }, {
          id: 6,
          name: "ae"
        }]
      }]
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchRoleList(data).then(function (response) {
        console.log(response);
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      console.log("添加角色");
      this.$refs["roleForm"].validate(function (valid) {
        if (valid) {
          console.log(_this2.roleForm);
          _this2.btnLoading = true;
          addRole(_this2.roleForm).then(function (res) {
            console.log(res);

            _this2.$message({
              type: "success",
              message: "添加角色成功"
            });

            _this2.roleDialog = false;
            _this2.btnLoading = false;

            _this2.getTableData();
          }).catch(function (error) {
            console.log(error);
            _this2.btnLoading = false;

            _this2.$message({
              type: "warning",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate() {
      var _this3 = this;

      console.log("修改角色");
      console.log(this.roleForm);
      this.$refs["roleForm"].validate(function (valid) {
        if (valid) {
          _this3.btnLoading = true;
          updateRole(_this3.roleForm).then(function (res) {
            console.log(res);

            _this3.$message({
              type: "success",
              message: "修改角色成功"
            });

            _this3.roleDialog = false;
            _this3.btnLoading = false;

            _this3.getTableData();
          }).catch(function (error) {
            _this3.btnLoading = false;

            _this3.$message({
              type: "warning",
              message: err.data["message"]
            });
          });
        }
      });
    },
    resetRole: function resetRole() {
      this.roleForm = {
        id: "",
        name: "",
        description: ""
      };
    },
    handleAdd: function handleAdd() {
      var _this4 = this;

      this.resetRole();
      this.roleDialog = true;
      this.dialogTitle = "create";
      this.$nextTick(function () {
        _this4.$refs["roleForm"].clearValidate();
      });
    },
    handlePermission: function handlePermission() {
      var _this5 = this;

      console.log("提交权限");
      console.log(this.authForm);
      var permission = this.authForm.permission;
      var permissions = permission.join(",");
      var data = {
        role_id: this.authForm.role_id,
        permission: permissions
      };
      this.btnLoading = true;
      updateRolePer(data).then(function (res) {
        console.log(res);

        _this5.$message({
          type: "success",
          message: "权限提交成功"
        });

        _this5.authDialog = false;
        _this5.btnLoading = false;

        _this5.getTableData();
      }).catch(function (error) {
        _this5.btnLoading = false;

        _this5.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleEditBtn: function handleEditBtn(row) {
      var _this6 = this;

      // 编辑
      this.roleForm = Object.assign({}, row);
      this.dialogTitle = "update";
      this.roleDialog = true;
      this.$nextTick(function () {
        _this6.$refs["roleForm"].clearValidate();
      });
    },
    handledelBtn: function handledelBtn(row) {
      var _this7 = this;

      // 删除
      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664".concat(row.name, "\u89D2\u8272?");
      var title = "删除";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        var data = row.id;
        delRole(data).then(function (res) {
          console.log(res);

          _this7.$message({
            type: "success",
            message: "删除成功"
          });

          var index = _this7.dataList.indexOf(row);

          _this7.dataList.splice(index, 1);
        }).catch(function (err) {
          _this7.$message({
            type: "warning",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    handleAuthBtn: function handleAuthBtn(row) {
      var _this8 = this;

      fetchPermission().then(function (res) {
        console.log(res);
        _this8.transferData = res.data;
      });
      this.authForm.role_id = row.id;
      this.authForm.permission = row.permissions;
      this.authDialog = true;
    }
  }
};